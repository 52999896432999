import React from "react";
const words = [
    { en: 'I', ru: 'Я' },
    { en: 'you', ru: 'ты, вы' },
    { en: 'happy', ru: 'счастливый' },
    { en: 'a boy', ru: 'мальчик' },
    { en: 'a child', ru: 'ребенок' },
    { en: 'a friend', ru: 'друг' },
    { en: 'a girl', ru: 'девочка' },
    { en: 'a maiden name', ru: 'девичья фамилия' },
    { en: 'a man', ru: 'мужчина' },
    { en: 'a name', ru: 'имя, название' },
    { en: 'a neighbour', ru: 'сосед' },
    { en: 'a person', ru: 'человек, персона' },
    { en: 'a surname', ru: 'фамилия' },
    { en: 'a woman', ru: 'женщина' },
    { en: 'an address', ru: 'адрес' },
    { en: 'age', ru: 'возраст' },
    { en: 'married', ru: 'женатый/замужняя' },
    { en: 'old', ru: 'старый' },
    { en: 'single', ru: 'холостой/незамужняя' },
    { en: 'we', ru: 'мы' },
    { en: 'they', ru: 'они' },
    { en: 'he', ru: 'он' },
    { en: 'she', ru: 'она' },
    { en: 'it', ru: 'оно (о неодушевленном в единственном числе)' },
    { en: 'my', ru: 'мой' },
    { en: 'your', ru: 'твой, ваш' },
    { en: 'our', ru: 'наш' },
    { en: 'their', ru: 'их' },
    { en: 'his', ru: 'его' },
    { en: 'her', ru: 'ее' },
    { en: 'its', ru: 'его, ее' },
    { en: 'a brother', ru: 'брат' },
    { en: 'a cousin', ru: 'двоюродный брат/сестра' },
    { en: 'a daughter', ru: 'дочь' },
    { en: 'a father', ru: 'отец' },
    { en: 'a grandfather', ru: 'дедушка' },
    { en: 'a grandmother', ru: 'бабушка' },
    { en: 'a husband', ru: 'муж' },
    { en: 'a mother', ru: 'мать' },
    { en: 'a nephew', ru: 'племянник' },
    { en: 'a niece', ru: 'племянница' },
    { en: 'a sister', ru: 'сестра' },
    { en: 'a son', ru: 'сын' },
    { en: 'a wife', ru: 'жена' },
    { en: 'an aunt', ru: 'тетя' },
    { en: 'an uncle', ru: 'дядя' },
    { en: 'parents', ru: 'родители' },
    { en: 'a back', ru: 'спина' },
    { en: 'a beard', ru: 'борода' },
    { en: 'a bone', ru: 'кость' },
    { en: 'a cheek', ru: 'щека' },
    { en: 'a chest', ru: 'грудь' },
    { en: 'a chin', ru: 'подбородок' },
    { en: 'a face', ru: 'лицо' },
    { en: 'a finger', ru: 'палец на руке' },
    { en: 'a foot', ru: 'ступня' },
    { en: 'a forehead', ru: 'лоб' },
    { en: 'a hand', ru: 'кисть руки' },
    { en: 'a head', ru: 'голова' },
    { en: 'a heart', ru: 'сердце' },
    { en: 'a heel', ru: 'пятка' },
    { en: 'a knee', ru: 'колено' },
    { en: 'a leg', ru: 'нога' },
    { en: 'a mouth', ru: 'рот' },
    { en: 'a mustache', ru: 'усы' },
    { en: 'a nail', ru: 'ноготь' },
    { en: 'a neck', ru: 'шея' },
    { en: 'a nose', ru: 'нос' },
    { en: 'a shoulder', ru: 'плечо' },
    { en: 'a spine', ru: 'позвоночник' },
    { en: 'a stomach', ru: 'живот, желудок' },
    { en: 'a throat', ru: 'горло' },
    { en: 'a toe', ru: 'палец на ноге' },
    { en: 'a tongue', ru: 'язык' },
    { en: 'a tooth', ru: 'зуб' },
    { en: 'an arm', ru: 'рука' },
    { en: 'an ear', ru: 'ухо' },
    { en: 'an elbow', ru: 'локоть' },
    { en: 'an eye', ru: 'глаз' },
    { en: 'an eyebrow', ru: 'бровь' },
    { en: 'an organ', ru: 'орган' },
    { en: 'buttocks', ru: 'ягодицы' },
    { en: 'hair', ru: 'волосы' },
    { en: 'lips', ru: 'губы' },
    { en: 'skin', ru: 'кожа' },
    { en: 'a bruise', ru: 'синяк' },
    { en: 'a spot', ru: 'пятно, точка' },
    { en: 'a wound', ru: 'рана' },
    { en: 'blood', ru: 'кровь' },
    { en: 'cough', ru: 'кашель' },
    { en: 'to cough', ru: 'кашлять' },
    { en: 'dizziness', ru: 'головокружение' },
    { en: 'fever', ru: 'жар' },
    { en: 'flu', ru: 'грипп' },
    { en: 'pain', ru: 'боль' },
    { en: 'running nose', ru: 'насморк' },
    { en: 'dead', ru: 'мертвый' },
    { en: 'fat', ru: 'толстый, жирный' },
    { en: 'fit', ru: 'в хорошей форме' },
    { en: 'healthy', ru: 'здоровый' },
    { en: 'sick', ru: 'больной' },
    { en: 'swollen', ru: 'опухший' },
    { en: 'to hurt', ru: 'ранить, повреждать' },
    { en: 'to sneeze', ru: 'чихать' },
    { en: 'amazing', ru: 'удивительный' },
    { en: 'beautiful', ru: 'красивый' },
    { en: 'brave', ru: 'смелый' },
    { en: 'calm', ru: 'спокойный' },
    { en: 'careful', ru: 'осторожный' },
    { en: 'clever', ru: 'умный' },
    { en: 'curious', ru: 'любопытный' },
    { en: 'elegant', ru: 'элегантный' },
    { en: 'energetic', ru: 'энергичный' },
    { en: 'fair', ru: 'справедливый' },
    { en: 'fine', ru: 'прекрасный, прекрасно' },
    { en: 'friendly', ru: 'дружелюбный' },
    { en: 'good', ru: 'хороший, добрый' },
    { en: 'handsome', ru: 'красивый (о мужчине)' },
    { en: 'hard-working', ru: 'трудолюбивый' },
    { en: 'honest', ru: 'честный' },
    { en: 'kind', ru: 'добрый' },
    { en: 'lucky', ru: 'везучий, счастливый' },
    { en: 'nice', ru: 'милый, хороший, приятный' },
    { en: 'polite', ru: 'вежливый' },
    { en: 'positive', ru: 'положительный' },
    { en: 'talented', ru: 'талантливый' },
    { en: 'average', ru: 'средний' },
    { en: 'boring', ru: 'скучный' },
    { en: 'busy', ru: 'занятой' },
    { en: 'clumsy', ru: 'неуклюжий' },
    { en: 'dear', ru: 'дорогой' },
    { en: 'different', ru: 'другой, разный' },
    { en: 'difficult', ru: 'трудный, тяжелый' },
    { en: 'easy', ru: 'легкий, нетрудный' },
    { en: 'embarrassed', ru: 'смущенный' },
    { en: 'famous', ru: 'знаменитый' },
    { en: 'important', ru: 'важный' },
    { en: 'impossible', ru: 'невозможный' },
    { en: 'interesting', ru: 'интересный' },
    { en: 'modern', ru: 'современный' },
    { en: 'neutral', ru: 'нейтральный' },
    { en: 'new', ru: 'новый' },
    { en: 'real', ru: 'настоящий' },
    { en: 'rich', ru: 'богатый' },
    { en: 'angry', ru: 'сердитый' },
    { en: 'annoying', ru: 'раздражающий' },
    { en: 'awful', ru: 'ужасный, отвратительный' },
    { en: 'bad', ru: 'плохой' },
    { en: 'crazy', ru: 'безумный, сумасшедший' },
    { en: 'dangerous', ru: 'опасный' },
    { en: 'jealous', ru: 'ревнивый' },
    { en: 'lazy', ru: 'ленивый' },
    { en: 'negative', ru: 'отрицательный' },
    { en: 'nervous', ru: 'нервный' },
    { en: 'poor', ru: 'бедный, плохой (язык, знания)' },
    { en: 'rude', ru: 'грубый' },
    { en: 'a boss', ru: 'босс' },
    { en: 'a businessman', ru: 'бизнесмен' },
    { en: 'a chief', ru: 'руководитель, главный' },
    { en: 'a client', ru: 'клиент' },
    { en: 'a colleague', ru: 'коллега' },
    { en: 'a doctor', ru: 'врач' },
    { en: 'a driver', ru: 'водитель' },
    { en: 'a flight attendant', ru: 'стюардесса, бортпроводник' },
    { en: 'a journalist', ru: 'журналист' },
    { en: 'a lawyer', ru: 'адвокат, юрист' },
    { en: 'a lifeguard', ru: 'спасатель' },
    { en: 'a mechanic', ru: 'механик' },
    { en: 'a musician', ru: 'музыкант' },
    { en: 'a nanny', ru: 'няня' },
    { en: 'a nurse', ru: 'медсестра' },
    { en: 'a pilot', ru: 'пилот' },
    { en: 'a plumber', ru: 'сантехник' },
    { en: 'a policeman', ru: 'полицейский' },
    { en: 'a pupil', ru: 'школьник' },
    { en: 'a shop assistant', ru: 'продавец' },
    { en: 'a student', ru: 'студент' },
    { en: 'a teacher', ru: 'учитель' },
    { en: 'a waiter', ru: 'официант' },
    { en: 'a worker', ru: 'рабочий' },
    { en: 'an accountant', ru: 'бухгалтер' },
    { en: 'an actor', ru: 'актер' },
    { en: 'an artist', ru: 'художник' },
    { en: 'an engineer', ru: 'инженер' },
    { en: 'a case', ru: 'дело' },
    { en: 'a circumstance', ru: 'обстоятельство' },
    { en: 'a decision', ru: 'решение' },
    { en: 'a duty', ru: 'обязанность' },
    { en: 'a goal', ru: 'цель' },
    { en: 'a list', ru: 'список' },
    { en: 'to list', ru: 'перечислять' },
    { en: 'a permit', ru: 'разрешение (на въезд/выезд/проживание)' },
    { en: 'a problem', ru: 'проблема' },
    { en: 'a reason', ru: 'причина' },
    { en: 'a result', ru: 'результат' },
    { en: 'a task', ru: 'задача, задание' },
    { en: 'an area', ru: 'область, сфера, район' },
    { en: 'an opportunity', ru: 'возможность' },
    { en: 'success', ru: 'успех' },
    { en: 'a belt', ru: 'пояс, ремень' },
    { en: 'a blouse', ru: 'блузка' },
    { en: 'a boot', ru: 'сапог, ботинок' },
    { en: 'a bracelet', ru: 'браслет' },
    { en: 'a cap', ru: 'кепка' },
    { en: 'a cardigan', ru: 'кардиган, кофта' },
    { en: 'a coat', ru: 'пальто' },
    { en: 'a dress', ru: 'платье' },
    { en: 'to dress', ru: 'одевать(ся)' },
    { en: 'a glove', ru: 'перчатка' },
    { en: 'a hat', ru: 'шляпа' },
    { en: 'a hood', ru: 'капюшон' },
    { en: 'a jacket', ru: 'жакет, пиджак, куртка' },
    { en: 'a jeans', ru: 'джинсы' },
    { en: 'a necklace', ru: 'ожерелье' },
    { en: 'a purse', ru: 'дамская сумка, кошелек' },
    { en: 'a scarf', ru: 'шарф' },
    { en: 'a shirt', ru: 'рубашка' },
    { en: 'a skirt', ru: 'юбка' },
    { en: 'a sock', ru: 'носок' },
    { en: 'a suit', ru: 'костюм' },
    { en: 'to suit', ru: 'идти к лицу' },
    { en: 'a sweater', ru: 'свитер' },
    { en: 'a T-shirt', ru: 'футболка' },
    { en: 'a tie', ru: 'галстук' },
    { en: 'a wallet', ru: 'бумажник' },
    { en: 'an earring', ru: 'сережка' },
    { en: 'an underwear', ru: 'нижнее белье' },
    { en: 'glasses', ru: 'очки' },
    { en: 'pyjamas', ru: 'пижама' },
    { en: 'shoes', ru: 'обувь' },
    { en: 'shorts', ru: 'шорты' },
    { en: 'tights', ru: 'колготы' },
    { en: 'trainers', ru: 'кроссовки' },
    { en: 'trousers', ru: 'штаны' },
    { en: 'black', ru: 'черный' },
    { en: 'blue', ru: 'голубой, синий' },
    { en: 'bright', ru: 'яркий' },
    { en: 'brown', ru: 'коричневый' },
    { en: 'colourful', ru: 'цветной' },
    { en: 'dark', ru: 'темный' },
    { en: 'green', ru: 'зеленый' },
    { en: 'grey', ru: 'серый' },
    { en: 'light', ru: 'светлый, легкий' },
    { en: 'orange', ru: 'оранжевый' },
    { en: 'pink', ru: 'розовый' },
    { en: 'purple', ru: 'фиолетовый' },
    { en: 'red', ru: 'красный' },
    { en: 'white', ru: 'белый' },
    { en: 'yellow', ru: 'желтый' },
    { en: 'a circle', ru: 'круг' },
    { en: 'a lot of', ru: 'много' },
    { en: 'a pair', ru: 'пара (вещей, объектов)' },
    { en: 'a part', ru: 'часть' },
    { en: 'a piece', ru: 'кусок, часть' },
    { en: 'a square', ru: 'квадрат' },
    { en: 'square', ru: 'квадратный' },
    { en: 'a wheel', ru: 'колесо' },
    { en: 'distance', ru: 'расстояние' },
    { en: 'size', ru: 'размер' },
    { en: 'speed', ru: 'скорость' },
    { en: 'strength', ru: 'сила' },
    { en: 'big', ru: 'большой' },
    { en: 'far', ru: 'далекий' },
    { en: 'fast', ru: 'быстрый, быстро' },
    { en: 'hard', ru: 'твердый, трудный, жесткий, усердно' },
    { en: 'heavy', ru: 'тяжелый' },
    { en: 'high', ru: 'высокий (об объектах)' },
    { en: 'large', ru: 'большой, просторный' },
    { en: 'long', ru: 'длинный, долгий' },
    { en: 'loud', ru: 'громкий' },
    { en: 'low', ru: 'низкий, низко' },
    { en: 'narrow', ru: 'узкий' },
    { en: 'near', ru: 'близкий' },
    { en: 'noisy', ru: 'шумный' },
    { en: 'powerful', ru: 'мощный' },
    { en: 'quick', ru: 'быстрый' },
    { en: 'quiet', ru: 'тихий' },
    { en: 'round', ru: 'округлый' },
    { en: 'short', ru: 'низкий, короткий' },
    { en: 'straight', ru: 'прямой, ровный' },
    { en: 'any', ru: 'несколько, некоторое количество (в отрицаниях и вопросах), какой-нибудь' },
    { en: 'few', ru: 'мало (с исчисляемыми существительными)' },
    { en: 'less', ru: 'меньше, менее' },
    { en: 'little', ru: 'мало (с неисчисляемыми существительными)' },
    { en: 'many', ru: 'много (с исчисляемыми существительными в отрицаниях и вопросах)' },
    { en: 'more', ru: 'больше, более' },
    { en: 'much', ru: 'много (с неисчисляемыми существительными в отрицаниях и вопросах)' },
    { en: 'some', ru: 'несколько, некоторое количество (в утверждениях)' },
    { en: 'again', ru: 'снова' },
    { en: 'all', ru: 'все, весь' },
    { en: 'almost', ru: 'почти' },
    { en: 'each', ru: 'каждый (по отдельности)' },
    { en: 'enough', ru: 'достаточно' },
    { en: 'every', ru: 'каждый' },
    { en: 'only', ru: 'только' },
    { en: 'than', ru: 'чем (в сравнениях)' },
    { en: 'together', ru: 'вместе' },
    { en: 'too', ru: 'тоже, слишком' },
    { en: 'very', ru: 'очень' },
    { en: 'zero', ru: 'ноль' },
    { en: 'one', ru: 'один' },
    { en: 'two', ru: 'два' },
    { en: 'three', ru: 'три' },
    { en: 'four', ru: 'четыре' },
    { en: 'five', ru: 'пять' },
    { en: 'six', ru: 'шесть' },
    { en: 'seven', ru: 'семь' },
    { en: 'eight', ru: 'восемь' },
    { en: 'nine', ru: 'девять' },
    { en: 'ten', ru: 'десять' },
    { en: 'eleven', ru: 'одиннадцать' },
    { en: 'twelve', ru: 'двенадцать' },
    { en: 'thirteen', ru: 'тринадцать' },
    { en: 'fourteen', ru: 'четырнадцать' },
    { en: 'fifteen', ru: 'пятнадцать' },
    { en: 'sixteen', ru: 'шестнадцать' },
    { en: 'seventeen', ru: 'семнадцать' },
    { en: 'eighteen', ru: 'восемнадцать' },
    { en: 'nineteen', ru: 'девятнадцать' },
    { en: 'twenty', ru: 'двадцать' },
    { en: 'thirty', ru: 'тридцать' },
    { en: 'forty', ru: 'сорок' },
    { en: 'fifty', ru: 'пятьдесят' },
    { en: 'sixty', ru: 'шестьдесят' },
    { en: 'seventy', ru: 'семьдесят' },
    { en: 'eighty', ru: 'восемьдесят' },
    { en: 'ninety', ru: 'девяносто' },
    { en: 'hundred', ru: 'сто' },
    { en: 'thousand', ru: 'тысяча' },
    { en: 'million', ru: 'миллион' },
    { en: 'first', ru: 'первый' },
    { en: 'second', ru: 'второй' },
    { en: 'third', ru: 'третий' },
    { en: 'fourth', ru: 'четвертый' },
    { en: 'fifth', ru: 'пятый' },
    { en: 'a season', ru: 'сезон, время года' },
    { en: 'winter', ru: 'зима' },
    { en: 'spring', ru: 'весна' },
    { en: 'summer', ru: 'лето' },
    { en: 'autumn', ru: 'осень' },
    { en: 'fall', ru: 'осень (AmE)' },
    { en: 'a month', ru: 'месяц' },
    { en: 'January', ru: 'январь' },
    { en: 'February', ru: 'февраль' },
    { en: 'March', ru: 'март' },
    { en: 'April', ru: 'апрель' },
    { en: 'May', ru: 'май' },
    { en: 'June', ru: 'июнь' },
    { en: 'July', ru: 'июль' },
    { en: 'August', ru: 'август' },
    { en: 'September', ru: 'сентябрь' },
    { en: 'October', ru: 'октябрь' },
    { en: 'November', ru: 'ноябрь' },
    { en: 'December', ru: 'декабрь' },
    { en: 'a day', ru: 'день' },
    { en: 'Monday', ru: 'понедельник' },
    { en: 'Tuesday', ru: 'вторник' },
    { en: 'Wednesday', ru: 'среда' },
    { en: 'Thursday', ru: 'четверг' },
    { en: 'Friday', ru: 'пятница' },
    { en: 'Saturday', ru: 'суббота' },
    { en: 'Sunday', ru: 'воскресенье' },
    { en: 'afternoon', ru: 'день (после полудня)' },
    { en: 'evening', ru: 'вечер' },
    { en: 'midnight', ru: 'полночь' },
    { en: 'morning', ru: 'утро' },
    { en: 'night', ru: 'ночь' },
    { en: 'now', ru: 'сейчас, теперь' },
    { en: 'today', ru: 'сегодня' },
    { en: 'tomorrow', ru: 'завтра' },
    { en: 'tonight', ru: 'сегодня вечером, ночью' },
    { en: 'yesterday', ru: 'вчера' },
    { en: 'a second', ru: 'секунда' },
    { en: 'a minute', ru: 'минута' },
    { en: 'an hour', ru: 'час' },
    { en: 'a week', ru: 'неделя' },
    { en: 'a year', ru: 'год' },
    { en: 'a century', ru: 'век' },
    { en: 'after', ru: 'после' },
    { en: 'ago', ru: 'назад (например, 2 дня назад)' },
    { en: 'already', ru: 'уже' },
    { en: 'always', ru: 'всегда' },
    { en: 'before', ru: 'до' },
    { en: 'during', ru: 'во время' },
    { en: 'early', ru: 'ранний, рано' },
    { en: 'for', ru: 'для, в течение (времени)' },
    { en: 'just', ru: 'только что, просто' },
    { en: 'last', ru: 'последний, прошлый' },
    { en: 'late', ru: 'поздний, поздно, опоздавший' },
    { en: 'never', ru: 'никогда' },
    { en: 'next', ru: 'следующий (год, неделя)' },
    { en: 'often', ru: 'часто' },
    { en: 'probably', ru: 'вероятно' },
    { en: 'really', ru: 'действительно' },
    { en: 'since', ru: 'с (определенного времени)' },
    { en: 'sometimes', ru: 'иногда' },
    { en: 'soon', ru: 'скоро' },
    { en: 'then', ru: 'затем, потом, тогда' },
    { en: 'usually', ru: 'обычно' },
    { en: 'will', ru: 'для образования будущего времени' },
    { en: 'yet', ru: 'еще не' },
    { en: 'a bill', ru: 'счет' },
    { en: 'cash', ru: 'наличка' },
    { en: 'discount', ru: 'скидка' },
    { en: 'price', ru: 'цена' },
    { en: 'tip', ru: 'чаевые' },
    { en: 'cheap', ru: 'дешевый' },
    { en: 'expensive', ru: 'дорогой (о цене)' },
    { en: 'free', ru: 'бесплатный, свободный' },
    { en: 'a dance', ru: 'танец' },
    { en: 'to dance', ru: 'танцевать' },
    { en: 'a film', ru: 'фильм' },
    { en: 'a movie', ru: 'фильм (AmE)' },
    { en: 'a photo', ru: 'фото' },
    { en: 'a picture', ru: 'картина, фотография' },
    { en: 'a play', ru: 'спектакль' },
    { en: 'to play', ru: 'играть' },
    { en: 'a song', ru: 'песня' },
    { en: 'a story', ru: 'история, рассказ' },
    { en: 'art', ru: 'искусство' },
    { en: 'literature', ru: 'литература' },
    { en: 'music', ru: 'музыка' },
    { en: 'to act', ru: 'играть (на сцене), вести себя' },
    { en: 'to build', ru: 'строить' },
    { en: 'to create', ru: 'создавать' },
    { en: 'to do', ru: 'делать' },
    { en: 'to draw', ru: 'рисовать' },
    { en: 'to listen', ru: 'слушать' },
    { en: 'to make', ru: 'делать, изготавливать' },
    { en: 'to read', ru: 'читать' },
    { en: 'to sing', ru: 'петь' },
    { en: 'to swim', ru: 'плавать' },
    { en: 'to teach', ru: 'учить, преподавать' },
    { en: 'to write', ru: 'писать' },
    { en: 'a backpack', ru: 'рюкзак' },
    { en: 'a bag', ru: 'сумка' },
    { en: 'a bicycle', ru: 'велосипед' },
    { en: 'a bus', ru: 'автобус' },
    { en: 'a car', ru: 'автомобиль' },
    { en: 'a card', ru: 'открытка, карточка (банковская)' },
    { en: 'a delay', ru: 'задержка' },
    { en: 'to delay', ru: 'задерживать' },
    { en: 'a destination', ru: 'пункт назначения' },
    { en: 'a luggage', ru: 'багаж' },
    { en: 'a map', ru: 'карта' },
    { en: 'a plane', ru: 'самолет' },
    { en: 'a ship', ru: 'корабль' },
    { en: 'a suitcase', ru: 'чемодан' },
    { en: 'a taxi', ru: 'такси' },
    { en: 'a ticket', ru: 'билет' },
    { en: 'a train', ru: 'поезд' },
    { en: 'a tram', ru: 'трамвай' },
    { en: 'insurance', ru: 'страховка' },
    { en: 'to arrive', ru: 'прибывать' },
    { en: 'to bring', ru: 'приносить' },
    { en: 'to catch', ru: 'ловить, успевать' },
    { en: 'to come', ru: 'приходить' },
    { en: 'to drive', ru: 'водить (машину)' },
    { en: 'to fly', ru: 'летать' },
    { en: 'to get', ru: 'получать, добираться' },
    { en: 'to give', ru: 'давать' },
    { en: 'to go', ru: 'идти, ехать' },
    { en: 'to leave', ru: 'уезжать, покидать' },
    { en: 'to move', ru: 'двигаться' },
    { en: 'to put', ru: 'класть, ставить' },
    { en: 'to ride', ru: 'ездить верхом' },
    { en: 'to run', ru: 'бежать' },
    { en: 'to send', ru: 'отправлять' },
    { en: 'to stay', ru: 'оставаться' },
    { en: 'to stop', ru: 'останавливаться' },
    { en: 'to take', ru: 'брать' },
    { en: 'to travel', ru: 'путешествовать' },
    { en: 'to turn', ru: 'поворачивать' },
    { en: 'to visit', ru: 'посещать' },
    { en: 'to walk', ru: 'идти пешком' },
    { en: 'a bed', ru: 'кровать' },
    { en: 'a chair', ru: 'стул' },
    { en: 'a door', ru: 'дверь' },
    { en: 'a floor', ru: 'пол' },
    { en: 'a furniture', ru: 'мебель' },
    { en: 'a house', ru: 'дом' },
    { en: 'a key', ru: 'ключ' },
    { en: 'a kitchen', ru: 'кухня' },
    { en: 'a lamp', ru: 'лампа' },
    { en: 'a mirror', ru: 'зеркало' },
    { en: 'a room', ru: 'комната' },
    { en: 'a shower', ru: 'душ' },
    { en: 'a sofa', ru: 'диван' },
    { en: 'a table', ru: 'стол' },
    { en: 'a toilet', ru: 'туалет' },
    { en: 'a wall', ru: 'стена' },
    { en: 'a window', ru: 'окно' },
    { en: 'an apartment', ru: 'квартира' },
    { en: 'an elevator', ru: 'лифт' },
    { en: 'a bank', ru: 'банк' },
    { en: 'a cafe', ru: 'кафе' },
    { en: 'a church', ru: 'церковь' },
    { en: 'a city', ru: 'город' },
    { en: 'a country', ru: 'страна' },
    { en: 'a hospital', ru: 'больница' },
    { en: 'a hotel', ru: 'отель' },
    { en: 'a library', ru: 'библиотека' },
    { en: 'a museum', ru: 'музей' },
    { en: 'a park', ru: 'парк' },
    { en: 'a place', ru: 'место' },
    { en: 'a post office', ru: 'почта' },
    { en: 'a restaurant', ru: 'ресторан' },
    { en: 'a school', ru: 'школа' },
    { en: 'a shop', ru: 'магазин' },
    { en: 'a street', ru: 'улица' },
    { en: 'a supermarket', ru: 'супермаркет' },
    { en: 'a theatre', ru: 'театр' },
    { en: 'a town', ru: 'городок' },
    { en: 'a village', ru: 'деревня' },
    { en: 'an airport', ru: 'аэропорт' },
    { en: 'a beach', ru: 'пляж' },
    { en: 'a bridge', ru: 'мост' },
    { en: 'a forest', ru: 'лес' },
    { en: 'a garden', ru: 'сад' },
    { en: 'a hill', ru: 'холм' },
    { en: 'a lake', ru: 'озеро' },
    { en: 'a mountain', ru: 'гора' },
    { en: 'a river', ru: 'река' },
    { en: 'a road', ru: 'дорога' },
    { en: 'a sea', ru: 'море' },
    { en: 'a tree', ru: 'дерево' },
    { en: 'air', ru: 'воздух' },
    { en: 'earth', ru: 'земля' },
    { en: 'fire', ru: 'огонь' },
    { en: 'grass', ru: 'трава' },
    { en: 'nature', ru: 'природа' },
    { en: 'rain', ru: 'дождь' },
    { en: 'sky', ru: 'небо' },
    { en: 'snow', ru: 'снег' },
    { en: 'sun', ru: 'солнце' },
    { en: 'water', ru: 'вода' },
    { en: 'weather', ru: 'погода' },
    { en: 'wind', ru: 'ветер' },
    { en: 'cold', ru: 'холодный' },
    { en: 'hot', ru: 'горячий' },
    { en: 'warm', ru: 'теплый' },
    { en: 'wet', ru: 'мокрый' },
    { en: 'a cat', ru: 'кошка' },
    { en: 'a dog', ru: 'собака' },
    { en: 'a fish', ru: 'рыба' },
    { en: 'a horse', ru: 'лошадь' },
    { en: 'an animal', ru: 'животное' },
    { en: 'a bird', ru: 'птица' },
    { en: 'a book', ru: 'книга' },
    { en: 'a box', ru: 'коробка' },
    { en: 'a clock', ru: 'часы (настенные)' },
    { en: 'a computer', ru: 'компьютер' },
    { en: 'a cup', ru: 'чашка' },
    { en: 'a gift', ru: 'подарок' },
    { en: 'a glass', ru: 'стакан' },
    { en: 'a letter', ru: 'письмо' },
    { en: 'a magazine', ru: 'журнал' },
    { en: 'a newspaper', ru: 'газета' },
    { en: 'a note', ru: 'записка' },
    { en: 'a pen', ru: 'ручка' },
    { en: 'a pencil', ru: 'карандаш' },
    { en: 'a phone', ru: 'телефон' },
    { en: 'a plate', ru: 'тарелка' },
    { en: 'a radio', ru: 'радио' },
    { en: 'a thing', ru: 'вещь' },
    { en: 'a watch', ru: 'часы (наручные)' },
    { en: 'an apple', ru: 'яблоко' },
    { en: 'an egg', ru: 'яйцо' },
    { en: 'an orange', ru: 'апельсин' },
    { en: 'bread', ru: 'хлеб' },
    { en: 'cheese', ru: 'сыр' },
    { en: 'coffee', ru: 'кофе' },
    { en: 'food', ru: 'еда' },
    { en: 'fruit', ru: 'фрукт' },
    { en: 'meat', ru: 'мясо' },
    { en: 'milk', ru: 'молоко' },
    { en: 'salt', ru: 'соль' },
    { en: 'sugar', ru: 'сахар' },
    { en: 'tea', ru: 'чай' },
    { en: 'vegetable', ru: 'овощ' },
    { en: 'water', ru: 'вода' },
    { en: 'to ask', ru: 'спрашивать' },
    { en: 'to answer', ru: 'отвечать' },
    { en: 'to begin', ru: 'начинать' },
    { en: 'to believe', ru: 'верить' },
    { en: 'to buy', ru: 'покупать' },
    { en: 'to call', ru: 'звонить' },
    { en: 'to change', ru: 'менять' },
    { en: 'to choose', ru: 'выбирать' },
    { en: 'to close', ru: 'закрывать' },
    { en: 'to cook', ru: 'готовить' },
    { en: 'to cry', ru: 'плакать' },
    { en: 'to die', ru: 'умирать' },
    { en: 'to drink', ru: 'пить' },
    { en: 'to eat', ru: 'есть' },
    { en: 'to end', ru: 'заканчивать' },
    { en: 'to explain', ru: 'объяснять' },
    { en: 'to fall', ru: 'падать' },
    { en: 'to feel', ru: 'чувствовать' },
    { en: 'to find', ru: 'находить' },
    { en: 'to finish', ru: 'заканчивать' },
    { en: 'to forget', ru: 'забывать' },
    { en: 'to forgive', ru: 'прощать' },
    { en: 'to get', ru: 'получать' },
    { en: 'to give', ru: 'давать' },
    { en: 'to happen', ru: 'случаться' },
    { en: 'to hate', ru: 'ненавидеть' },
    { en: 'to have', ru: 'иметь' },
    { en: 'to hear', ru: 'слышать' },
    { en: 'to help', ru: 'помогать' },
    { en: 'to hope', ru: 'надеяться' },
    { en: 'to keep', ru: 'хранить' },
    { en: 'to know', ru: 'знать' },
    { en: 'to learn', ru: 'учиться' },
    { en: 'to like', ru: 'нравиться' },
    { en: 'to live', ru: 'жить' },
    { en: 'to look', ru: 'смотреть' },
    { en: 'to love', ru: 'любить' },
    { en: 'to mean', ru: 'значить' },
    { en: 'to meet', ru: 'встречать' },
    { en: 'to need', ru: 'нуждаться' },
    { en: 'to open', ru: 'открывать' },
    { en: 'to pay', ru: 'платить' },
    { en: 'to remember', ru: 'помнить' },
    { en: 'to say', ru: 'говорить' },
    { en: 'to see', ru: 'видеть' },
    { en: 'to sell', ru: 'продавать' },
    { en: 'to show', ru: 'показывать' },
    { en: 'to sleep', ru: 'спать' },
    { en: 'to speak', ru: 'говорить' },
    { en: 'to start', ru: 'начинать' },
    { en: 'to study', ru: 'учиться' },
    { en: 'to talk', ru: 'разговаривать' },
    { en: 'to tell', ru: 'рассказывать' },
    { en: 'to think', ru: 'думать' },
    { en: 'to try', ru: 'пытаться' },
    { en: 'to understand', ru: 'понимать' },
    { en: 'to use', ru: 'использовать' },
    { en: 'to wait', ru: 'ждать' },
    { en: 'to want', ru: 'хотеть' },
    { en: 'to watch', ru: 'смотреть' },
    { en: 'to win', ru: 'выигрывать' },
    { en: 'to work', ru: 'работать' },
    { en: 'to write', ru: 'писать' },
    { en: 'a backpack', ru: 'рюкзак' },
    { en: 'a bag', ru: 'сумка' },
    { en: 'a bicycle', ru: 'велосипед' },
    { en: 'a bus', ru: 'автобус' },
    { en: 'a car', ru: 'автомобиль' },
    { en: 'a card', ru: 'открытка, карточка (банковская)' },
    { en: 'a delay', ru: 'задержка' },
    { en: 'to delay', ru: 'задерживать' },
    { en: 'a destination', ru: 'пункт назначения' },
    { en: 'a luggage', ru: 'багаж' },
    { en: 'a map', ru: 'карта' },
    { en: 'a plane', ru: 'самолет' },
    { en: 'a ship', ru: 'корабль' },
    { en: 'a suitcase', ru: 'чемодан' },
    { en: 'a taxi', ru: 'такси' },
    { en: 'a ticket', ru: 'билет' },
    { en: 'a train', ru: 'поезд' },
    { en: 'a tram', ru: 'трамвай' },
    { en: 'insurance', ru: 'страховка' },
    { en: 'to arrive', ru: 'прибывать' },
    { en: 'to bring', ru: 'приносить' },
    { en: 'to catch', ru: 'ловить, успевать' },
    { en: 'to come', ru: 'приходить' },
    { en: 'to drive', ru: 'водить (машину)' },
    { en: 'to fly', ru: 'летать' },
    { en: 'to get', ru: 'получать, добираться' },
    { en: 'to give', ru: 'давать' },
    { en: 'to go', ru: 'идти, ехать' },
    { en: 'to leave', ru: 'уезжать, покидать' },
    { en: 'to move', ru: 'двигаться' },
    { en: 'to put', ru: 'класть, ставить' },
    { en: 'to ride', ru: 'ездить верхом' },
    { en: 'to run', ru: 'бежать' },
    { en: 'to send', ru: 'отправлять' },
    { en: 'to stay', ru: 'оставаться' },
    { en: 'to stop', ru: 'останавливаться' },
    { en: 'to take', ru: 'брать' },
    { en: 'to travel', ru: 'путешествовать' },
    { en: 'to turn', ru: 'поворачивать' },
    { en: 'to visit', ru: 'посещать' },
    { en: 'to walk', ru: 'идти пешком' },
    { en: 'a bed', ru: 'кровать' },
    { en: 'a chair', ru: 'стул' },
    { en: 'a door', ru: 'дверь' },
    { en: 'a floor', ru: 'пол' },
    { en: 'a furniture', ru: 'мебель' },
    { en: 'a house', ru: 'дом' },
    { en: 'a key', ru: 'ключ' },
    { en: 'a kitchen', ru: 'кухня' },
    { en: 'a lamp', ru: 'лампа' },
    { en: 'a mirror', ru: 'зеркало' },
    { en: 'a room', ru: 'комната' },
    { en: 'a shower', ru: 'душ' },
    { en: 'a sofa', ru: 'диван' },
    { en: 'a table', ru: 'стол' },
    { en: 'a toilet', ru: 'туалет' },
    { en: 'a wall', ru: 'стена' },
    { en: 'a window', ru: 'окно' },
    { en: 'an apartment', ru: 'квартира' },
    { en: 'an elevator', ru: 'лифт' },
    { en: 'a bank', ru: 'банк' },
    { en: 'a cafe', ru: 'кафе' },
    { en: 'a church', ru: 'церковь' },
    { en: 'a city', ru: 'город' },
    { en: 'a country', ru: 'страна' },
    { en: 'a hospital', ru: 'больница' },
    { en: 'a hotel', ru: 'отель' },
    { en: 'a library', ru: 'библиотека' },
    { en: 'a museum', ru: 'музей' },
    { en: 'a park', ru: 'парк' },
    { en: 'a place', ru: 'место' },
    { en: 'a post office', ru: 'почта' },
    { en: 'a restaurant', ru: 'ресторан' },
    { en: 'a school', ru: 'школа' },
    { en: 'a shop', ru: 'магазин' },
    { en: 'a street', ru: 'улица' },
    { en: 'a supermarket', ru: 'супермаркет' },
    { en: 'a theatre', ru: 'театр' },
    { en: 'a town', ru: 'городок' },
    { en: 'a village', ru: 'деревня' },
    { en: 'an airport', ru: 'аэропорт' },
    { en: 'a beach', ru: 'пляж' },
    { en: 'a bridge', ru: 'мост' },
    { en: 'a forest', ru: 'лес' },
    { en: 'a garden', ru: 'сад' },
    { en: 'a hill', ru: 'холм' },
    { en: 'a lake', ru: 'озеро' },
    { en: 'a mountain', ru: 'гора' },
    { en: 'a river', ru: 'река' },
    { en: 'a road', ru: 'дорога' },
    { en: 'a sea', ru: 'море' },
    { en: 'a tree', ru: 'дерево' },
    { en: 'air', ru: 'воздух' },
    { en: 'earth', ru: 'земля' },
    { en: 'fire', ru: 'огонь' },
    { en: 'grass', ru: 'трава' },
    { en: 'nature', ru: 'природа' },
    { en: 'rain', ru: 'дождь' },
    { en: 'sky', ru: 'небо' },
    { en: 'snow', ru: 'снег' },
    { en: 'sun', ru: 'солнце' },
    { en: 'water', ru: 'вода' },
    { en: 'weather', ru: 'погода' },
    { en: 'wind', ru: 'ветер' }
  ];

  words.forEach(obj => {
    obj.status = false; // Вы можете установить любое значение
  });
  export default words;